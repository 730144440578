@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

$grid-columns: 24;
$grid-gutter-width: 30px;

$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 3),
  6: ($spacer * 5),
);

// default color override
$primary:          #2b2ba0;
$primary-50:       #2b2ba050;
$primary-dark:     #27277C;
$primary-light:    #bfbfff80;
$link:             #2b2ba0;
$secondary:        #999999;
$medium:           #666666;
$success:          #b8238e;
$warning:          #F9ED32;
$warning-lighter:  #fff2cc;
$danger:           #FD003A;
$danger-light:     #E72064;
$danger-lighter:   #f4cccc;
$danger-dark:      #A30000;
$disabled:         #a3a3a3;
$light:            #F4F4F4;
$dark:             #333333;
$green-lighter:    #d9ead3;
$green:            #25d366;
$white:            #FFFFFF;
$black:            #000000;
$border:           #f0f0f5;


$theme-colors: (
  "primary":        $primary,
  "primary-50":     $primary-50,
  "primary-dark":   $primary-dark,
  "primary-light":  $primary-light,
  "link":           $link,
  "secondary":      $secondary,
  "medium":         $medium,
  "success":        $success,
  "info":           $info,
  "warning":        $warning,
  "warning-lighter":$warning-lighter,
  "danger":         $danger,
  "danger-lighter": $danger-lighter,
  "danger-light":   $danger-light,
  "danger-dark":    $danger-dark,
  "disabled":       $disabled,
  "light":          $light,
  "dark":           $dark,
  "green":          $green,
  "green-lighter":  $green-lighter,
  "white":          $white,
  "black":          $black,
  "border":         $border,
);