@import './bootstrap.scss';
@import '~bootstrap/scss/bootstrap';
@import './CustomerLocation.scss';
@import './checkout.scss';
@import './OrderManagment/PlaceOrder.scss';
@import './OrderManagment/Orders.scss';
@import '~bootstrap-daterangepicker/daterangepicker.css';
@import './ShareOfferCard.scss';

@font-face {
  font-family: 'latoItalicBlack';
  src: url('./Lato-BlackItalic.ttf');
}

html {
  font-size: 16px!important;
  @media screen and (max-width: 576px) {
    font-size: 14px!important;
  }
  @media screen and (max-width: 450px) {
    font-size: 13px!important;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px!important;
  }
  @media screen and (max-width: 330px) {
    font-size: 11px!important;
  }
}

.container {
  @media screen and (max-width: 1200px) {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
    max-width: 100%;
  }
}

.h-50p {
  height: 50px;
}

.w-50p {
  width: 50px;
}

.textCentered {
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-section {
  height: calc(100% - 50px);
}

.feed-container {
  height: calc(100% - 50px);
  overflow-y: scroll;
}

.fs-01 {
  font-size: 0.75rem;
  @media screen and (max-width: 576px){
    font-size: 0.85rem;
  }
}

.fs-0 {
  font-size: 0.9rem;
}

.fs-1 {
  font-size: 1rem;
}

.fs-2 {
  font-size: 1.25rem;
}

.fs-3 {
  font-size: 1.5rem;
}

.fs-4 {
  font-size: 1.75rem;
}

.fs-5 {
  font-size: 2rem;
}

.fs-6 {
  font-size: 2.25rem;
}

.fs-7 {
  font-size: 2.5rem;
}

.font-black-italic {
  font-family: 'latoItalicBlack', sans-serif;
}

input {
  outline: none;
}

.pt-50p {
  padding-top: 50px;
}

.btn.disabled, .btn:disabled {
  opacity: 1;
  background-color: #ccc;
  color: $white;
  border: #ccc;
  box-shadow: none;
}

a, a:hover, .btn-link, .btn-link:hover, .btn-link:not(:disabled):not(.disabled):active,
.btn-link:not(:disabled):not(.disabled).active, .show > .btn-link.dropdown-toggle,
.btn-link:not(:disabled):not(.disabled):active:focus, .btn-link:not(:disabled):not(.disabled).active:focus,
.btn-link.dropdown-toggle:focus, .btn-link:focus {
  color: $primary;
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a.text-white:hover, a.text-white:focus {
  color: $white !important;
}

.btn-link:disabled, .btn-link.disabled {
  color: $secondary;
  background-color: transparent;
  border-color: transparent;
  pointer-events: none;
}

.overflow-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y {
  overflow-x: hidden;
  overflow-y: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.table-list {
  height: 100%;
  overflow-y: auto;
}

.table-list .filter-section, #retailer-setting .filter-section {
  position: sticky;
  top: 0;
  z-index: 100;
}

.product-image-div {
  width: 60px;
  height: 60px;
  @media screen and (max-width: 768px){
    width: 50px;
    height: 50px;
  }
}

.product-image-div img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

::-webkit-scrollbar {
  width: 18px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: #d3d3d3;
}

.pointer-event-none {
  pointer-events: none;
}

.form-check-input {
  margin-top: 0.1rem !important;
  padding: 2px;
}

.minw-120p {
  min-width: 120px;
}

.minw-300p {
  min-width: 300px;
}

.maxh-200p {
  max-height: 200px;
}

.w-100p {
  width: 100px;
}

.w-150p{
  width: 150px;
}

.w-210p {
  width: 210px;
}

.opacity-0 {
  opacity: 0;
}

#backdrop {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background: #000000;
  opacity: 0.29;
  top: 0
}

.search-suggestion {
  position: absolute;
  z-index: 200;
  min-width: 100%;
  max-height: 200px;
  overflow-y: auto;
  background: $white;
  box-shadow: 0 0 3px $secondary;
}

.invalid-input {
  border-color: $danger;
  box-shadow: inherit inherit inherit $danger;
}

.meta-data, .clickable-meta-data {
  border: 1px solid #dee2de;
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.15) !important;
}

.clickable-meta-data {
  cursor: pointer;
}

.clickable-meta-data:hover {
  background: $light !important;
}

.cursor-pointer {
  cursor: pointer;
}

.absolute-0 {
  position: absolute;
  top: 0;
}

.position-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.z-1005 {
  z-index: 1005;
}

.dropdown-menu {
  z-index: 1050;
}

.dropdown-item.active {
  background-color: $primary;
}

.product-image-container {
  width: 80px;
  height: 80px;
  border: 1px solid $border;
  display: flex;
  align-items: center;
  justify-content: center;
}

.store-image-container {
  width: 20px;
  height: 20px;
}

.product-image {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.colored-circle {
  border-radius: 50%;
  height: 40px;
  width: 40px;
  display: inline-block;
  border: 2px solid #bfbfff80;
}

.minw-80p {
  min-width: 80px;
}

.banner-image {
  height: 20px !important;
  @media screen and (min-width: 450px) {
    height: 40px !important;
  }
  @media screen and (min-width: 576px) {
    height: 30px !important;
  }
  @media screen and (min-width: 768px) {
    height: 40px !important;
  }
  @media screen and (min-width: 992px) {
    height: 70px !important;
  }
  @media screen and (min-width: 1200px) {
    height: 80px !important;
  }
}

.border-rounded {
  border-radius: 50%;
}

.input-file {
  border: none;
  padding: 0;
}

.border-radius-4 {
  border-radius: 0.25rem;
}

.border-radius-16 {
  border-radius: 1rem;
}

.break-word {
  word-break: break-all;
}

.text-truncate-2 {
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  word-break: break-all;
  text-overflow: ellipsis;
  overflow: hidden;
}

.minw-150p {
  min-width: 150px;
}

.on-hover-underline:hover {
  text-decoration: underline;
}

.banner-height {
  max-height: 750px;
  overflow: auto;
}

.stories-container {
  max-height: 690px; 
  overflow: auto;
}

.text-orange {
  color: #FFA500;
}

.pointer-events-none {
  pointer-events: none;  
}

.border-bottom-green {
  border-bottom: 1px solid green;
}

.edit-product-image-div {
  width: 75px;
  height: 75px;
  @media screen and (max-width: 768px){
    width: 50px;
    height: 50px;
  }
}

.edit-product-image-div img {
  width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.border-bottom-1 {
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 1px solid #bfbfff80!important;
}

.flex_no_wrap{
  flex-wrap: nowrap;
}
