#retailer-setting .settings-showcase {
  height: calc(100% - 50px);
  overflow-y: scroll;
  background-color: #ffffff;
  position: absolute;
}

.delivery-switch {
  width: 50%;
  margin-left: auto;
  height: 50px;
  position: fixed;
  top: 50px;
  right: 0;
  background: white;
  display: flex;
  align-items: center;
  z-index: 100;
}

.settings-header {
  position: fixed;
  top: 67px;
  width: 100%;
  z-index: 2;
  @media screen and (min-width: 992px) {
    left: 20.84%;
    right: 0;
    width: auto;
  }
}

.settings-header.app {
  top: 0px;
}

.setup-input {
  max-width: 100%;
  @media screen and (min-width: 576px) {
    max-width: 320px;
  }
}

.google-map-div {
  min-width: 90%;
  min-height: 360px;
}

.map-div-override {
  position: absolute !important;
  min-width: 90% !important;
  min-height: 360px;
  z-index: 2;
}

.setup-input-2 {
  max-width: 100%;
  @media screen and (min-width: 576px) {
    max-width: 157px;
  }
}

.settings-nav {
  position: sticky;
  z-index: 1020;
  top: 0px;
}

.account-details-modal {
  // max-height: 400px;
  overflow-y: scroll;
}

.rc-time-picker-input {
  border: none!important;
  border-radius: 0!important;
  font-size: 16px!important;
  border-bottom: 2px solid #2b2ba0!important;
  color: #2b2ba0!important;
  font-weight: 600!important;
  width: 90px!important;
}

.rc-time-picker-input[disabled] {
  border: none;
  background-color: #FFFFFF!important;
  color: #333333!important;
  border-bottom: 2px solid #cccccc !important;
}

.rc-time-picker-panel-input {
  font-size: 16px;
  color: #2b2ba0;
  font-weight: 600;
}

.checkbox {
  width: 18px;
  height: 18px;
  padding: 0 0.12rem;
}

.checkbox.checked {
  background: url('../../icon/checkbox-check.svg') no-repeat center;
  background-color: #2b2ba0;
  background-origin: content-box;
}

.w-50p {
  width: 50px;
}

.border-bottom-primary {
  border-bottom: 2px solid #2b2ba0 !important;
}


.rounded-button {
  border-radius: 50% !important;
  height: 1.5rem;
  width: 1.5rem;
}
