#order-details .product-image-div{
    max-width: 100px !important;
    max-height: 100px !important;
    @media screen and (max-width: 768px) {
        max-width: 50px !important;
        max-height: 50px !important;
    }
}

.headers {
    background-color: #2b2ba0;
    color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-bottom: 8px;
}

.button-in-header {
    background-color: white !important;
    color: #2b2ba0 !important;
    font-weight: bold !important;
}

.custom-product-tab {
    background-color: #fffcd6;
    color: black;
}

.product-section {
    max-height: 500px;
    overflow: scroll;
    background-color: white;
    margin-bottom: 8px;
}
